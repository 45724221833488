@font-face {
  font-family: 'icomoon';
  src: url('fonts/icomoon.eot?z2s61k');
  src: url('fonts/icomoon.eot?z2s61k#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?z2s61k') format('truetype'),
    url('fonts/icomoon.woff?z2s61k') format('woff'),
    url('fonts/icomoon.svg?z2s61k#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^='icon-'],
[class*=' icon-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-post-test:before {
  content: '\eb4a';
}
.icon-thumbs-up-fill:before {
  content: '\eb48';
}
.icon-thumbs-up-stroke:before {
  content: '\eb49';
}
.icon-add-store:before {
  content: '\eaee';
}
.icon-add:before {
  content: '\eaef';
}
.icon-album-photo:before {
  content: '\eaf0';
}
.icon-audio-mp3:before {
  content: '\eaf1';
}
.icon-Bar-chart:before {
  content: '\eaf2';
}
.icon-bell-fill:before {
  content: '\eaf3';
}
.icon-boost:before {
  content: '\eaf4';
}
.icon-salesPoint:before {
  content: '\eaf5';
}
.icon-campaign-fill-1:before {
  content: '\eaf6';
}
.icon-carousel:before {
  content: '\eaf7';
}
.icon-cart-shopping-outline:before {
  content: '\eaf8';
}
.icon-colorize-pipette:before {
  content: '\eaf9';
}
.icon-contact:before {
  content: '\eafa';
}
.icon-copy-file:before {
  content: '\eafb';
}
.icon-delete-trash:before {
  content: '\eafc';
}
.icon-desactivate_contact:before {
  content: '\eafd';
}
.icon-disabled_visible:before {
  content: '\eafe';
}
.icon-discussion-comment:before {
  content: '\eaff';
}
.icon-document-file:before {
  content: '\eb00';
}
.icon-download-preview:before {
  content: '\eb01';
}
.icon-drag_n_drop:before {
  content: '\eb02';
}
.icon-edit_outline:before {
  content: '\eb03';
}
.icon-edit_photo:before {
  content: '\eb04';
}
.icon-email-add:before {
  content: '\eb05';
}
.icon-email-template:before {
  content: '\eb06';
}
.icon-email-at:before {
  content: '\eb07';
}
.icon-email-card:before {
  content: '\eb08';
}
.icon-email-file:before {
  content: '\eb09';
}
.icon-export:before {
  content: '\eb0a';
}
.icon-facebook-bg:before {
  content: '\eb0b';
}
.icon-feed:before {
  content: '\eb0c';
}
.icon-file-upload:before {
  content: '\eb0d';
}
.icon-file-duplicate:before {
  content: '\eb0e';
}
.icon-filter-off:before {
  content: '\eb0f';
}
.icon-filter-on:before {
  content: '\eb10';
}
.icon-filter:before {
  content: '\eb11';
}
.icon-fix:before {
  content: '\eb12';
}
.icon-gear:before {
  content: '\eb13';
}
.icon-gif:before {
  content: '\eb14';
}
.icon-import:before {
  content: '\eb15';
}
.icon-jpg-image:before {
  content: '\eb16';
}
.icon-landscape:before {
  content: '\eb17';
}
.icon-like-outline:before {
  content: '\eb18';
}
.icon-like-recommend:before {
  content: '\eb19';
}
.icon-map-world:before {
  content: '\eb1a';
}
.icon-mark-as-read:before {
  content: '\eb1b';
}
.icon-mark-as-unread:before {
  content: '\eb1c';
}
.icon-minus-remove:before {
  content: '\eb1d';
}
.icon-mobile:before {
  content: '\eb1e';
}
.icon-monitor-add:before {
  content: '\eb1f';
}
.icon-monitor-fill:before {
  content: '\eb20';
}
.icon-movie:before {
  content: '\eb21';
}
.icon-notification-off-outline:before {
  content: '\eb22';
}
.icon-notification-off:before {
  content: '\eb23';
}
.icon-notification-on-outline:before {
  content: '\eb24';
}
.icon-notification:before {
  content: '\eb25';
}
.icon-page:before {
  content: '\eb26';
}
.icon-palette:before {
  content: '\eb27';
}
.icon-pdf:before {
  content: '\eb28';
}
.icon-photo-picture-add:before {
  content: '\eb29';
}
.icon-photo-picture-1:before {
  content: '\eb2a';
}
.icon-photo-picture:before {
  content: '\eb2b';
}
.icon-pin-outline:before {
  content: '\eb2c';
}
.icon-play-rectangle:before {
  content: '\eb2d';
}
.icon-position-left:before {
  content: '\eb2e';
}
.icon-position-right:before {
  content: '\eb2f';
}
.icon-position-venter:before {
  content: '\eb30';
}
.icon-posts:before {
  content: '\eb31';
}
.icon-question-mark-info-circle-feedback:before {
  content: '\eb32';
}
.icon-radio-player:before {
  content: '\eb33';
}
.icon-see-users:before {
  content: '\eb34';
}
.icon-send-to-all:before {
  content: '\eb35';
}
.icon-send:before {
  content: '\eb36';
}
.icon-sentiment_satisfied_alt:before {
  content: '\eb37';
}
.icon-settings_action:before {
  content: '\eb38';
}
.icon-share-arrow:before {
  content: '\eb39';
}
.icon-shopping-cart-download:before {
  content: '\eb3a';
}
.icon-small-close:before {
  content: '\eb3b';
}
.icon-sms-add:before {
  content: '\eb3c';
}
.icon-sms-template:before {
  content: '\eb3d';
}
.icon-sms-1:before {
  content: '\eb3e';
}
.icon-sms:before {
  content: '\eb3f';
}
.icon-star-outline:before {
  content: '\eb40';
}
.icon-store-off:before {
  content: '\eb41';
}
.icon-store-outline:before {
  content: '\eb42';
}
.icon-target:before {
  content: '\eb43';
}
.icon-trash-outline:before {
  content: '\eb44';
}
.icon-unlike:before {
  content: '\eb45';
}
.icon-view-module-2:before {
  content: '\eb46';
}
.icon-view_list:before {
  content: '\eb47';
}
.icon-headphone:before {
  content: '\eaed';
}
.icon-pause:before {
  content: '\eaeb';
}
.icon-play:before {
  content: '\eaec';
}
.icon-add-template-sms:before {
  content: '\eac7';
}
.icon-add-template-email:before {
  content: '\eac8';
}
.icon-add-file:before {
  content: '\eac9';
}
.icon-alert-circle-outline:before {
  content: '\eaca';
}
.icon-alert-polygon-outline-Copy:before {
  content: '\eacb';
}
.icon-arrow-left-2:before {
  content: '\eacc';
}
.icon-check-fill:before {
  content: '\eacd';
}
.icon-contact1:before {
  content: '\eace';
}
.icon-copy-file-outline:before {
  content: '\eacf';
}
.icon-deactivate-contact:before {
  content: '\ead0';
}
.icon-edit-outline:before {
  content: '\ead1';
}
.icon-email1:before {
  content: '\ead2';
}
.icon-emailing-copy:before {
  content: '\ead3';
}
.icon-eye-outline:before {
  content: '\ead4';
}
.icon-file-outline:before {
  content: '\ead5';
}
.icon-folder-fill1:before {
  content: '\ead6';
}
.icon-jpg:before {
  content: '\ead7';
}
.icon-move-file-outline:before {
  content: '\ead8';
}
.icon-movie1:before {
  content: '\ead9';
}
.icon-mp3:before {
  content: '\eada';
}
.icon-palette-outline:before {
  content: '\eadb';
}
.icon-pdf1:before {
  content: '\eadc';
}
.icon-Radio:before {
  content: '\eadd';
}
.icon-refresh1:before {
  content: '\eade';
}
.icon-replicate:before {
  content: '\eadf';
}
.icon-see_users:before {
  content: '\eae0';
}
.icon-setting:before {
  content: '\eae1';
}
.icon-sms1:before {
  content: '\eae2';
}
.icon-star-outline1:before {
  content: '\eae3';
}
.icon-store-off-fill-Copy:before {
  content: '\eae4';
}
.icon-template-email:before {
  content: '\eae5';
}
.icon-template-sms:before {
  content: '\eae6';
}
.icon-trash-21:before {
  content: '\eae7';
}
.icon-upload-document:before {
  content: '\eae8';
}
.icon-upload-file:before {
  content: '\eae9';
}
.icon-view-list:before {
  content: '\eaea';
}
.icon-foursquare:before {
  content: '\eac1';
}
.icon-vimeo:before {
  content: '\eac2';
}
.icon-xing:before {
  content: '\eac3';
}
.icon-brand:before {
  content: '\eac3';
}
.icon-social:before {
  content: '\eac3';
}
.icon-youtube:before {
  content: '\eac4';
}
.icon-brand1:before {
  content: '\eac4';
}
.icon-social1:before {
  content: '\eac4';
}
.icon-pinterest:before {
  content: '\e900';
}
.icon-instagram:before {
  content: '\e901';
}
.icon-activity:before {
  content: '\e902';
}
.icon-add-store-fill:before {
  content: '\e903';
}
.icon-add-to-queue-fill:before {
  content: '\e904';
}
.icon-alert-circle-fill:before {
  content: '\e905';
}
.icon-alert-circle:before {
  content: '\e906';
}
.icon-alert-triangle-fill:before {
  content: '\e907';
}
.icon-alert-triangle:before {
  content: '\e908';
}
.icon-all-done:before {
  content: '\e909';
}
.icon-archive-fill:before {
  content: '\e90a';
}
.icon-archive:before {
  content: '\e90b';
}
.icon-arrow-circle-down-fill:before {
  content: '\e90c';
}
.icon-arrow-circle-down:before {
  content: '\e90d';
}
.icon-arrow-circle-left-fill:before {
  content: '\e90e';
}
.icon-arrow-circle-left:before {
  content: '\e90f';
}
.icon-arrow-circle-right-fill:before {
  content: '\e910';
}
.icon-arrow-circle-right:before {
  content: '\e911';
}
.icon-arrow-circle-up-fill:before {
  content: '\e912';
}
.icon-arrow-circle-up:before {
  content: '\e913';
}
.icon-arrow-down-fill:before {
  content: '\e914';
}
.icon-arrow-down:before {
  content: '\e915';
}
.icon-arrow-drop-down-fill:before {
  content: '\e916';
}
.icon-arrow-drop-up-fill:before {
  content: '\e917';
}
.icon-arrow-ios-down:before {
  content: '\e918';
}
.icon-arrow-ios-left:before {
  content: '\e919';
}
.icon-arrow-ios-right:before {
  content: '\e91a';
}
.icon-arrow-ios-up:before {
  content: '\e91b';
}
.icon-arrow-left-fill:before {
  content: '\e91c';
}
.icon-arrow-left:before {
  content: '\e91d';
}
.icon-arrow-right-fill:before {
  content: '\e91e';
}
.icon-arrow-right:before {
  content: '\e91f';
}
.icon-arrow-up-fill:before {
  content: '\e920';
}
.icon-arrow-up:before {
  content: '\e921';
}
.icon-arrowhead-down:before {
  content: '\e922';
}
.icon-arrowhead-left:before {
  content: '\e923';
}
.icon-arrowhead-right:before {
  content: '\e924';
}
.icon-arrowhead-up:before {
  content: '\e925';
}
.icon-arrrow-left-fill:before {
  content: '\e926';
}
.icon-arrrow-left:before {
  content: '\e927';
}
.icon-at-fill:before {
  content: '\e928';
}
.icon-at:before {
  content: '\e929';
}
.icon-attach-2:before {
  content: '\e92a';
}
.icon-attach:before {
  content: '\e92b';
}
.icon-award-fill:before {
  content: '\e92c';
}
.icon-award:before {
  content: '\e92d';
}
.icon-backspace-fill:before {
  content: '\e92e';
}
.icon-backspace:before {
  content: '\e92f';
}
.icon-bar-chart-2:before {
  content: '\e930';
}
.icon-bar-chart:before {
  content: '\e931';
}
.icon-battery-fill:before {
  content: '\e932';
}
.icon-battery:before {
  content: '\e933';
}
.icon-batttery-charging-fill:before {
  content: '\e934';
}
.icon-batttery-charging:before {
  content: '\e935';
}
.icon-behance-fill:before {
  content: '\e936';
}
.icon-bell-off-fill:before {
  content: '\e937';
}
.icon-bell-off:before {
  content: '\e938';
}
.icon-bell:before {
  content: '\e939';
}
.icon-binary:before {
  content: '\e93a';
}
.icon-bluetooth:before {
  content: '\e93b';
}
.icon-book-fill:before {
  content: '\e93c';
}
.icon-book-open-fill:before {
  content: '\e93d';
}
.icon-book-open:before {
  content: '\e93e';
}
.icon-book:before {
  content: '\e93f';
}
.icon-bookmark-fill:before {
  content: '\e940';
}
.icon-bookmark:before {
  content: '\e941';
}
.icon-briefcase-fill:before {
  content: '\e942';
}
.icon-briefcase:before {
  content: '\e943';
}
.icon-browser-fill:before {
  content: '\e944';
}
.icon-browser:before {
  content: '\e945';
}
.icon-brush-fill:before {
  content: '\e946';
}
.icon-brush:before {
  content: '\e947';
}
.icon-bulb-fill:before {
  content: '\e948';
}
.icon-bulb:before {
  content: '\e949';
}
.icon-calendar-fill:before {
  content: '\e94a';
}
.icon-calendar:before {
  content: '\e94b';
}
.icon-camera-fill:before {
  content: '\e94c';
}
.icon-camera:before {
  content: '\e94d';
}
.icon-car-fill:before {
  content: '\e94e';
}
.icon-car:before {
  content: '\e94f';
}
.icon-cast:before {
  content: '\e950';
}
.icon-catalog-fill:before {
  content: '\e951';
}
.icon-catalog:before {
  content: '\e952';
}
.icon-checkmark-circle-2:before {
  content: '\e953';
}
.icon-checkmark-circle-fill:before {
  content: '\e954';
}
.icon-checkmark-circle:before {
  content: '\e955';
}
.icon-checkmark-square-2:before {
  content: '\e956';
}
.icon-checkmark-square-fill:before {
  content: '\e957';
}
.icon-checkmark-square:before {
  content: '\e958';
}
.icon-checkmark:before {
  content: '\e959';
}
.icon-chevron-down:before {
  content: '\e95a';
}
.icon-chevron-left:before {
  content: '\e95b';
}
.icon-chevron-right:before {
  content: '\e95c';
}
.icon-chevron-up:before {
  content: '\e95d';
}
.icon-clipboard-fill:before {
  content: '\e95e';
}
.icon-clipboard:before {
  content: '\e95f';
}
.icon-clock-fill:before {
  content: '\e960';
}
.icon-clock:before {
  content: '\e961';
}
.icon-close-circle-fill:before {
  content: '\e962';
}
.icon-close-circle:before {
  content: '\e963';
}
.icon-Close-fill:before {
  content: '\e964';
}
.icon-close-square-fill:before {
  content: '\e965';
}
.icon-close:before {
  content: '\e966';
}
.icon-cloud-download-fill:before {
  content: '\e967';
}
.icon-cloud-download:before {
  content: '\e968';
}
.icon-cloud-upload-fill:before {
  content: '\e969';
}
.icon-cloud-upload:before {
  content: '\e96a';
}
.icon-code-download:before {
  content: '\e96b';
}
.icon-code:before {
  content: '\e96c';
}
.icon-collapse:before {
  content: '\e96d';
}
.icon-color-palette-fill:before {
  content: '\e96e';
}
.icon-color-palette:before {
  content: '\e96f';
}
.icon-color-picker-fill:before {
  content: '\e970';
}
.icon-color-picker:before {
  content: '\e971';
}
.icon-compass-fill:before {
  content: '\e972';
}
.icon-compass:before {
  content: '\e973';
}
.icon-copy-fill:before {
  content: '\e974';
}
.icon-copy:before {
  content: '\e975';
}
.icon-corner-down-left:before {
  content: '\e976';
}
.icon-corner-down-right:before {
  content: '\e977';
}
.icon-corner-left-down:before {
  content: '\e978';
}
.icon-corner-left-up:before {
  content: '\e979';
}
.icon-corner-right-down:before {
  content: '\e97a';
}
.icon-corner-right-up:before {
  content: '\e97b';
}
.icon-corner-up-left:before {
  content: '\e97c';
}
.icon-corner-up-right:before {
  content: '\e97d';
}
.icon-credit-card-fill:before {
  content: '\e97e';
}
.icon-credit-card:before {
  content: '\e97f';
}
.icon-crop:before {
  content: '\e980';
}
.icon-csv-fill:before {
  content: '\e981';
}
.icon-cube-fill:before {
  content: '\e982';
}
.icon-cube:before {
  content: '\e983';
}
.icon-diagonal-arrow-left-down:before {
  content: '\e984';
}
.icon-diagonal-arrow-left-up:before {
  content: '\e985';
}
.icon-diagonal-arrow-right-down:before {
  content: '\e986';
}
.icon-diagonal-arrow-right-up:before {
  content: '\e987';
}
.icon-download-fill:before {
  content: '\e988';
}
.icon-download:before {
  content: '\e989';
}
.icon-droplet-fill:before {
  content: '\e98a';
}
.icon-droplet-off-fill:before {
  content: '\e98b';
}
.icon-droplet-off:before {
  content: '\e98c';
}
.icon-droplet:before {
  content: '\e98d';
}
.icon-edit-2:before {
  content: '\e98e';
}
.icon-edit-fill:before {
  content: '\e98f';
}
.icon-edit:before {
  content: '\e990';
}
.icon-email-fill:before {
  content: '\e991';
}
.icon-email:before {
  content: '\e992';
}
.icon-expand:before {
  content: '\e993';
}
.icon-export-fill:before {
  content: '\e994';
}
.icon-external-link:before {
  content: '\e995';
}
.icon-eye-fill:before {
  content: '\e996';
}
.icon-eye-off-2:before {
  content: '\e997';
}
.icon-eye-off-fill:before {
  content: '\e998';
}
.icon-eye-off:before {
  content: '\e999';
}
.icon-eye:before {
  content: '\e99a';
}
.icon-facebook-fill:before {
  content: '\e99b';
}
.icon-facebook:before {
  content: '\e99c';
}
.icon-feedback-fill:before {
  content: '\e99d';
}
.icon-file-add-fill:before {
  content: '\e99e';
}
.icon-file-add:before {
  content: '\e99f';
}
.icon-file-fill:before {
  content: '\e9a0';
}
.icon-file-remove-fill:before {
  content: '\e9a1';
}
.icon-file-remove:before {
  content: '\e9a2';
}
.icon-file-text-fill:before {
  content: '\e9a3';
}
.icon-file-text:before {
  content: '\e9a4';
}
.icon-file:before {
  content: '\e9a5';
}
.icon-film-fill:before {
  content: '\e9a6';
}
.icon-film:before {
  content: '\e9a7';
}
.icon-filter-off-fill:before {
  content: '\e9a8';
}
.icon-filter-on-fill:before {
  content: '\e9a9';
}
.icon-fire-fill:before {
  content: '\e9aa';
}
.icon-fire:before {
  content: '\e9ab';
}
.icon-flag-fill:before {
  content: '\e9ac';
}
.icon-flag:before {
  content: '\e9ad';
}
.icon-flash-fill:before {
  content: '\e9ae';
}
.icon-flash-off-fill:before {
  content: '\e9af';
}
.icon-flash-off:before {
  content: '\e9b0';
}
.icon-flash:before {
  content: '\e9b1';
}
.icon-flip-2:before {
  content: '\e9b2';
}
.icon-flip:before {
  content: '\e9b3';
}
.icon-folder-add-fill:before {
  content: '\e9b4';
}
.icon-folder-add:before {
  content: '\e9b5';
}
.icon-folder-fill:before {
  content: '\e9b6';
}
.icon-folder-remove-fill:before {
  content: '\e9b7';
}
.icon-folder-remove:before {
  content: '\e9b8';
}
.icon-folder:before {
  content: '\e9b9';
}
.icon-funnel-fill:before {
  content: '\e9ba';
}
.icon-funnel:before {
  content: '\e9bb';
}
.icon-gift-fill:before {
  content: '\e9bc';
}
.icon-gift:before {
  content: '\e9bd';
}
.icon-github-fill:before {
  content: '\e9be';
}
.icon-github:before {
  content: '\e9bf';
}
.icon-globe-2-fill:before {
  content: '\e9c0';
}
.icon-globe-2:before {
  content: '\e9c1';
}
.icon-globe:before {
  content: '\e9c2';
}
.icon-google-fill:before {
  content: '\e9c3';
}
.icon-google:before {
  content: '\e9c4';
}
.icon-grid-fill:before {
  content: '\e9c5';
}
.icon-grid:before {
  content: '\e9c6';
}
.icon-hard-drive-fill:before {
  content: '\e9c7';
}
.icon-hard-drive:before {
  content: '\e9c8';
}
.icon-hash:before {
  content: '\e9c9';
}
.icon-headphones-fill:before {
  content: '\e9ca';
}
.icon-headphones:before {
  content: '\e9cb';
}
.icon-heart-fill:before {
  content: '\e9cc';
}
.icon-heart:before {
  content: '\e9cd';
}
.icon-height:before {
  content: '\e9ce';
}
.icon-help-fill:before {
  content: '\e9cf';
}
.icon-home-fill:before {
  content: '\e9d0';
}
.icon-home:before {
  content: '\e9d1';
}
.icon-image-fill:before {
  content: '\e9d2';
}
.icon-image:before {
  content: '\e9d3';
}
.icon-import-fill:before {
  content: '\e9d4';
}
.icon-inbox-fill:before {
  content: '\e9d5';
}
.icon-inbox:before {
  content: '\e9d6';
}
.icon-info-2-fill:before {
  content: '\e9d7';
}
.icon-info-fill:before {
  content: '\e9d8';
}
.icon-info:before {
  content: '\e9d9';
}
.icon-keypad-fill:before {
  content: '\e9da';
}
.icon-keypad:before {
  content: '\e9db';
}
.icon-layers-fill:before {
  content: '\e9dc';
}
.icon-layers:before {
  content: '\e9dd';
}
.icon-layout-fill:before {
  content: '\e9de';
}
.icon-layout:before {
  content: '\e9df';
}
.icon-link-2-fill:before {
  content: '\e9e0';
}
.icon-link-2:before {
  content: '\e9e1';
}
.icon-link:before {
  content: '\e9e2';
}
.icon-linkedin-fill:before {
  content: '\e9e3';
}
.icon-linkedin:before {
  content: '\e9e4';
}
.icon-list:before {
  content: '\e9e5';
}
.icon-loader:before {
  content: '\e9e6';
}
.icon-local:before {
  content: '\e9e7';
}
.icon-lock-fill:before {
  content: '\e9e8';
}
.icon-lock:before {
  content: '\e9e9';
}
.icon-log-in:before {
  content: '\e9ea';
}
.icon-log-out:before {
  content: '\e9eb';
}
.icon-mail-fill:before {
  content: '\e9ec';
}
.icon-map-fill:before {
  content: '\e9ed';
}
.icon-map:before {
  content: '\e9ee';
}
.icon-maximize-fill:before {
  content: '\e9ef';
}
.icon-maximize:before {
  content: '\e9f0';
}
.icon-megaphone-fill:before {
  content: '\e9f1';
}
.icon-menu-2:before {
  content: '\e9f2';
}
.icon-menu-arrow:before {
  content: '\e9f3';
}
.icon-menu-fill:before {
  content: '\e9f4';
}
.icon-menu:before {
  content: '\e9f5';
}
.icon-message-circle-fill:before {
  content: '\e9f6';
}
.icon-message-circle:before {
  content: '\e9f7';
}
.icon-message-square-fill:before {
  content: '\e9f8';
}
.icon-message-square:before {
  content: '\e9f9';
}
.icon-mic-fill:before {
  content: '\e9fa';
}
.icon-mic-off-fill:before {
  content: '\e9fb';
}
.icon-mic-off:before {
  content: '\e9fc';
}
.icon-mic:before {
  content: '\e9fd';
}
.icon-minimize-fill:before {
  content: '\e9fe';
}
.icon-minimize:before {
  content: '\e9ff';
}
.icon-minus-circle-fill:before {
  content: '\ea00';
}
.icon-minus-circle:before {
  content: '\ea01';
}
.icon-minus-fill:before {
  content: '\ea02';
}
.icon-minus-square-fill:before {
  content: '\ea03';
}
.icon-minus-square:before {
  content: '\ea04';
}
.icon-minus:before {
  content: '\ea05';
}
.icon-monitor:before {
  content: '\ea06';
}
.icon-monitor1:before {
  content: '\ea07';
}
.icon-moon-fill:before {
  content: '\ea08';
}
.icon-moon:before {
  content: '\ea09';
}
.icon-more-horizontal:before {
  content: '\ea0a';
}
.icon-more-vertical:before {
  content: '\ea0b';
}
.icon-move:before {
  content: '\ea0c';
}
.icon-music-fill:before {
  content: '\ea0d';
}
.icon-music:before {
  content: '\ea0e';
}
.icon-national:before {
  content: '\ea0f';
}
.icon-navigation-2-fill:before {
  content: '\ea10';
}
.icon-navigation-2:before {
  content: '\ea11';
}
.icon-navigation-fill:before {
  content: '\ea12';
}
.icon-navigation:before {
  content: '\ea13';
}
.icon-npm-fill:before {
  content: '\ea14';
}
.icon-npm:before {
  content: '\ea15';
}
.icon-options-2-fill:before {
  content: '\ea16';
}
.icon-options-2:before {
  content: '\ea17';
}
.icon-options-fill:before {
  content: '\ea18';
}
.icon-options:before {
  content: '\ea19';
}
.icon-pantone-fill:before {
  content: '\ea1a';
}
.icon-pantone:before {
  content: '\ea1b';
}
.icon-paper-plane:before {
  content: '\ea1c';
}
.icon-pause-circle-fill:before {
  content: '\ea1d';
}
.icon-pause-circle:before {
  content: '\ea1e';
}
.icon-people-fill:before {
  content: '\ea1f';
}
.icon-people-plus-fill:before {
  content: '\ea20';
}
.icon-people:before {
  content: '\ea21';
}
.icon-percent:before {
  content: '\ea22';
}
.icon-person-add-fill:before {
  content: '\ea23';
}
.icon-person-add:before {
  content: '\ea24';
}
.icon-person-delete-fill:before {
  content: '\ea25';
}
.icon-person-delete:before {
  content: '\ea26';
}
.icon-person-done-fill:before {
  content: '\ea27';
}
.icon-person-done:before {
  content: '\ea28';
}
.icon-person-fill:before {
  content: '\ea29';
}
.icon-person-remove-fill:before {
  content: '\ea2a';
}
.icon-person-remove:before {
  content: '\ea2b';
}
.icon-person:before {
  content: '\ea2c';
}
.icon-phone-call-fill:before {
  content: '\ea2d';
}
.icon-phone-call:before {
  content: '\ea2e';
}
.icon-phone-fill:before {
  content: '\ea2f';
}
.icon-phone-missed-fill:before {
  content: '\ea30';
}
.icon-phone-missed:before {
  content: '\ea31';
}
.icon-phone-off-fill:before {
  content: '\ea32';
}
.icon-phone-off:before {
  content: '\ea33';
}
.icon-phone:before {
  content: '\ea34';
}
.icon-pie-chart--fill:before {
  content: '\ea35';
}
.icon-pie-chart-fill:before {
  content: '\ea36';
}
.icon-pie-chart:before {
  content: '\ea37';
}
.icon-pin-fill:before {
  content: '\ea38';
}
.icon-pin:before {
  content: '\ea39';
}
.icon-play-circle-fill:before {
  content: '\ea3a';
}
.icon-play-circle:before {
  content: '\ea3b';
}
.icon-plus-circle-fill:before {
  content: '\ea3c';
}
.icon-plus-circle:before {
  content: '\ea3d';
}
.icon-plus-fill:before {
  content: '\ea3e';
}
.icon-plus-square-fill:before {
  content: '\ea3f';
}
.icon-plus-square:before {
  content: '\ea40';
}
.icon-plus:before {
  content: '\ea41';
}
.icon-position-center-fill:before {
  content: '\ea42';
}
.icon-position-left-fill:before {
  content: '\ea43';
}
.icon-position-right-fill:before {
  content: '\ea44';
}
.icon-power-fill:before {
  content: '\ea45';
}
.icon-power:before {
  content: '\ea46';
}
.icon-preview-fill:before {
  content: '\ea47';
}
.icon-pricetag-fill:before {
  content: '\ea48';
}
.icon-pricetag:before {
  content: '\ea49';
}
.icon-printer-fill:before {
  content: '\ea4a';
}
.icon-printer:before {
  content: '\ea4b';
}
.icon-promo-fill:before {
  content: '\ea4c';
}
.icon-question-mark-circle-fill:before {
  content: '\ea4d';
}
.icon-question-mark-circle:before {
  content: '\ea4e';
}
.icon-question-mark:before {
  content: '\ea4f';
}
.icon-radio-button-off:before {
  content: '\ea50';
}
.icon-radio-button-on-fill:before {
  content: '\ea51';
}
.icon-radio-button-on:before {
  content: '\ea52';
}
.icon-radio-fill:before {
  content: '\ea53';
}
.icon-radio:before {
  content: '\ea54';
}
.icon-recording-fill:before {
  content: '\ea55';
}
.icon-recording:before {
  content: '\ea56';
}
.icon-refresh:before {
  content: '\ea57';
}
.icon-regional:before {
  content: '\ea58';
}
.icon-repeat:before {
  content: '\ea59';
}
.icon-rewind-left-fill:before {
  content: '\ea5a';
}
.icon-rewind-left:before {
  content: '\ea5b';
}
.icon-rewind-right-fill:before {
  content: '\ea5c';
}
.icon-rewind-right:before {
  content: '\ea5d';
}
.icon-save-fill:before {
  content: '\ea5e';
}
.icon-save:before {
  content: '\ea5f';
}
.icon-scissors:before {
  content: '\ea60';
}
.icon-search-fill:before {
  content: '\ea61';
}
.icon-search:before {
  content: '\ea62';
}
.icon-send-fill:before {
  content: '\ea63';
}
.icon-settings-2-fill:before {
  content: '\ea64';
}
.icon-settings-2:before {
  content: '\ea65';
}
.icon-settings-fill:before {
  content: '\ea66';
}
.icon-settings:before {
  content: '\ea67';
}
.icon-shake:before {
  content: '\ea68';
}
.icon-share-fill:before {
  content: '\ea69';
}
.icon-share:before {
  content: '\ea6a';
}
.icon-shield-fill:before {
  content: '\ea6b';
}
.icon-shield-off-fill:before {
  content: '\ea6c';
}
.icon-shield-off:before {
  content: '\ea6d';
}
.icon-shield:before {
  content: '\ea6e';
}
.icon-shopping-bag-fill:before {
  content: '\ea6f';
}
.icon-shopping-bag:before {
  content: '\ea70';
}
.icon-shopping-cart-empty:before {
  content: '\ea71';
}
.icon-shopping-cart-fill:before {
  content: '\ea72';
}
.icon-shopping-cart:before {
  content: '\ea73';
}
.icon-shuffle-2:before {
  content: '\ea74';
}
.icon-shuffle:before {
  content: '\ea75';
}
.icon-skip-back-fill:before {
  content: '\ea76';
}
.icon-skip-back:before {
  content: '\ea77';
}
.icon-skip-forward-fill:before {
  content: '\ea78';
}
.icon-skip-forward:before {
  content: '\ea79';
}
.icon-slash:before {
  content: '\ea7a';
}
.icon-smartphone-fill:before {
  content: '\ea7b';
}
.icon-smartphone:before {
  content: '\ea7c';
}
.icon-speaker-fill:before {
  content: '\ea7d';
}
.icon-speaker:before {
  content: '\ea7e';
}
.icon-square:before {
  content: '\ea7f';
}
.icon-star-fill:before {
  content: '\ea80';
}
.icon-star:before {
  content: '\ea81';
}
.icon-stop-circle-fill:before {
  content: '\ea82';
}
.icon-stop-circle:before {
  content: '\ea83';
}
.icon-store-fill:before {
  content: '\ea84';
}
.icon-store-off-fill:before {
  content: '\ea85';
}
.icon-store-pin:before {
  content: '\ea86';
}
.icon-store:before {
  content: '\ea87';
}
.icon-sun-fill:before {
  content: '\ea88';
}
.icon-sun:before {
  content: '\ea89';
}
.icon-swap:before {
  content: '\ea8a';
}
.icon-sync:before {
  content: '\ea8b';
}
.icon-tech-devices:before {
  content: '\ea8c';
}
.icon-text:before {
  content: '\ea8d';
}
.icon-thermometer-fill:before {
  content: '\ea8e';
}
.icon-thermometer-minus-fill:before {
  content: '\ea8f';
}
.icon-thermometer-minus:before {
  content: '\ea90';
}
.icon-thermometer-plus-fill:before {
  content: '\ea91';
}
.icon-thermometer-plus:before {
  content: '\ea92';
}
.icon-thermometer:before {
  content: '\ea93';
}
.icon-time-money:before {
  content: '\ea94';
}
.icon-timer-fill:before {
  content: '\ea95';
}
.icon-timer:before {
  content: '\ea96';
}
.icon-toggle-left-fill:before {
  content: '\ea97';
}
.icon-toggle-left:before {
  content: '\ea98';
}
.icon-toggle-right-fill:before {
  content: '\ea99';
}
.icon-toggle-right:before {
  content: '\ea9a';
}
.icon-trash-2-fill:before {
  content: '\ea9b';
}
.icon-trash-2:before {
  content: '\ea9c';
}
.icon-trash-fill:before {
  content: '\ea9d';
}
.icon-trash:before {
  content: '\ea9e';
}
.icon-trending-down:before {
  content: '\ea9f';
}
.icon-trending-up:before {
  content: '\eaa0';
}
.icon-tv-fill:before {
  content: '\eaa1';
}
.icon-tv:before {
  content: '\eaa2';
}
.icon-twitter-fill:before {
  content: '\eaa3';
}
.icon-twitter:before {
  content: '\eaa4';
}
.icon-umbrella-fill:before {
  content: '\eaa5';
}
.icon-umbrella:before {
  content: '\eaa6';
}
.icon-undo-fill:before {
  content: '\eaa7';
}
.icon-undo:before {
  content: '\eaa8';
}
.icon-unlock-fill:before {
  content: '\eaa9';
}
.icon-unlock:before {
  content: '\eaaa';
}
.icon-upload:before {
  content: '\eaab';
}
.icon-user-time:before {
  content: '\eaac';
}
.icon-video-fill:before {
  content: '\eaad';
}
.icon-video-off-fill:before {
  content: '\eaae';
}
.icon-video-off:before {
  content: '\eaaf';
}
.icon-video:before {
  content: '\eab0';
}
.icon-view-agenda-fill:before {
  content: '\eab1';
}
.icon-view-module-fill:before {
  content: '\eab2';
}
.icon-volume-down-fill:before {
  content: '\eab3';
}
.icon-volume-down:before {
  content: '\eab4';
}
.icon-volume-fill:before {
  content: '\eab5';
}
.icon-volume-off-fill:before {
  content: '\eab6';
}
.icon-volume-off:before {
  content: '\eab7';
}
.icon-volume-up-fill:before {
  content: '\eab8';
}
.icon-volume-up:before {
  content: '\eab9';
}
.icon-volume:before {
  content: '\eaba';
}
.icon-weight-fill:before {
  content: '\eabb';
}
.icon-weight:before {
  content: '\eabc';
}
.icon-wifi-off:before {
  content: '\eabd';
}
.icon-wifi:before {
  content: '\eabe';
}
.icon-zoom-in:before {
  content: '\eabf';
}
.icon-zoom-out:before {
  content: '\eac0';
}
.icon-view-module:before {
  content: '\eac5';
}
.icon-view-agenda:before {
  content: '\eac6';
}
.icon-recommandation:before {
  content: '\eb4b';
}
