@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  .text-vertical {
    writing-mode: sideways-lr;
    text-orientation: sideways;
  }

  /* Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }

  .no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  .form-calendar::-webkit-inner-spin-button,
  .form-calendar::-webkit-calendar-picker-indicator {
    display: none;
    -webkit-appearance: none;
  }
}