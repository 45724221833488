@font-face {
  font-family: 'Rubik';
  src: url('./fonts/rubik/Rubik-Light.ttf');
  src: url('./fonts/rubik/Rubik-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Rubik';
  src: url('./fonts/rubik/Rubik-Regular.ttf');
  src: url('./fonts/rubik/Rubik-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Rubik';
  src: url('./fonts/rubik/Rubik-Medium.ttf');
  src: url('./fonts/rubik/Rubik-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Rubik';
  src: url('./fonts/rubik/Rubik-Bold.ttf');
  src: url('./fonts/rubik/Rubik-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Rubik';
  src: url('./fonts/rubik/Rubik-Black.ttf');
  src: url('./fonts/rubik/Rubik-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins';
  src: url('./fonts/poppins/Poppins-Thin.ttf');
  src: url('./fonts/poppins/Poppins-Thin.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins';
  src: url('./fonts/poppins/Poppins-ExtraLight.ttf');
  src: url('./fonts/poppins/Poppins-ExtraLight.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins';
  src: url('./fonts/poppins/Poppins-Light.ttf');
  src: url('./fonts/poppins/Poppins-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins';
  src: url('./fonts/poppins/Poppins-Regular.ttf');
  src: url('./fonts/poppins/Poppins-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins';
  src: url('./fonts/poppins/Poppins-Medium.ttf');
  src: url('./fonts/poppins/Poppins-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins';
  src: url('./fonts/poppins/Poppins-SemiBold.ttf');
  src: url('./fonts/poppins/Poppins-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins';
  src: url('./fonts/poppins/Poppins-Bold.ttf');
  src: url('./fonts/poppins/Poppins-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins';
  src: url('./fonts/poppins/Poppins-ExtraBold.ttf');
  src: url('./fonts/poppins/Poppins-ExtraBold.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins';
  src: url('./fonts/poppins/Poppins-Black.ttf');
  src: url('./fonts/poppins/Poppins-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}
