@import 'react-big-calendar/lib/css/react-big-calendar.css';
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');
:root {
  --primaryBoxShadow: rgb(19 16 13 / 0.5);
--primaryBackGround: rgb(19 16 13 / 0.2);
--primary100: #908C88;
--primary200: #6C6864;
--primary300: #474440;
--primary400: #2C2A27;
--primary500: #13100D;
--primary600: #100D0B;
--primary700: #0D0B09;
--primary800: #0B0907;
--primary900: #090706;
--info100: #F2F8FF;
--info200: #C7E2FF;
--info300: #94CBFF;
--info400: #42AAFF;
--info500: #0095FF;
--info600: #006FD6;
--info700: #0057C2;
--info800: #0041A8;
--info900: #002885;
--success100: #E3FFEF;
--success200: #BCF8D6;
--success300: #81ECAF;
--success400: #37C173;
--success500: #0EBA59;
--success600: #009538;
--success700: #007216;
--success800: #005000;
--success900: #003100;
--warning100: #FFFBF7;
--warning200: #FFE7D6;
--warning300: #FFCDA9;
--warning400: #FFA969;
--warning500: #FB7A1A;
--warning600: #D15800;
--warning700: #A83500;
--warning800: #810D00;
--warning900: #5E0000;
--danger100: #FFF1F0;
--danger200: #FEE1DF;
--danger300: #FFB4AF;
--danger400: #FF6B61;
--danger500: #F44336;
--danger600: #D0191D;
--danger700: #AC0002;
--danger800: #890000;
--danger900: #690000;
--white: #FFFFFF;
--white600: #F7F9FC;
--white700: #F2F5F8;
--white800: #EDF1F7;
--black: #000000;
--grey100: #F4F4F4;
--grey200: #E4E4E4;
--grey300: #D5D5D5;
--grey400: #C7C7C7;
--grey500: #BABABA;
--grey600: #ADADAD;
--grey700: #A1A1A1;
--grey800: #969696;
--grey900: #8C8C8C;
--lightgrey: #999694;
--text100: #D1D2E2;
--text200: #A7A7B7;
--text300: #7E7F8E;
--text400: #585967;
--text500: #343542;
--text600: #272835;
--text700: #1F202B;
--text800: #191A25;
--text900: #161620;
--yellow100: #FDF7E9;
--yellow200: #F9E7BF;
--yellow300: #F5D895;
--yellow400: #F1C86B;
--yellow500: #EDB941;
--yellow600: #E1A315;
--yellow700: #AD7E10;
--yellow800: #7A590C;
--yellow900: #473307;
--radio: #0f7070;
--presse: #56b6af;
--video: #3366ff;
--facebook: #0095FF;
--twitter: #0084B4;
--pinterest: #C8232C;
--youtube: #FF0000;
--linkedin: #0A66C2;
--vimeo: #1A2E3B;
--xing: #006567;
--foursquare: #E65778;
--instagram: #E1306C;
--google: #DD4B39;
--bronze:  #7C6750;
--download: #FFE180;
--google_link: #1a73e8;
--cyan300: #67e8f9;
--cyan400: #22d3ee;
--cyan500: #06b6d4;
--cyan600: #0891b2;
--cyan700: #0e7490;
--pink300: #f9a8d4;
--pink400: #f472b6;
--pink500: #ec4899;
--pink600: #db2777;
--pink700: #be185d;
--rose300: #fda4af;
--rose400: #fb7185;
--rose500: #f43f5e;
--rose600: #e11d48;
--rose700: #be123c;
--indigo300: #a5b4fc;
--indigo400: #818cf8;
--indigo500: #6366f1;
--indigo600: #4f46e5;
--indigo700: #4338ca;
--amber300: #fcd34d;
--amber400: #fbbf24;
--amber500: #f59e0b;
--amber600: #d97706;
--amber700: #b45309;
--emerald300: #6ee7b7;
--emerald400: #34d399;
--emerald500: #10b981;
--emerald600: #059669;
--emerald700: #047857;
}
  